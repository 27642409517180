































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import SelectButtonGroup from '@/components/forms/SelectButtonGroup.vue'
import EditBooking from './components/EditBooking.vue'
import { BookingModel } from '@/core/models/BookingModel'
import { BookingFormInterface } from './components/BookingForm.vue'
import breakpoints from '@/plugins/breakpoints'
import { v4 as uuidv4 } from 'uuid'
import {
  toLocalMonth,
  toLocalFullDate,
  toLocalDateISO,
} from '@/utils/dateFormatter'

import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

const CalendarMonth = () => import('./components/CalendarMonth.vue')
const CalendarDay = () => import('./components/CalendarDay.vue')

@Component({
  name: 'CalendarPage',
  components: {
    BaseTitle,
    BaseButton,
    BaseIcon,
    SelectButtonGroup,
    EditBooking,
    CalendarMonth,
    CalendarDay,
    VueCustomScrollbar,
  },
  metaInfo: {
    title: 'Календарь',
  },
})
export default class extends Vue {
  @Watch('breakpoints.width')
  onWidthChanged(value: number): void {
    if (value < 1200) this.period = ''
  }

  toLocalMonth = toLocalMonth
  toLocalFullDate = toLocalFullDate
  uuidv4 = uuidv4
  current = new Date()
  today = new Date()
  periodItems = [
    { id: 'day', title: 'День' },
    /* { id: 'week', title: 'Неделя', disabled: true }, */
    { id: '', title: 'Месяц' },
  ]
  period = ''
  breakpoints = breakpoints
  model: BookingFormInterface = {}
  showEditBooking = false
  calendarKey = uuidv4()

  error = ''
  saveDisabled = false

  @RoomsStore.Action
  private getRooms!: () => Promise<RoomModel[]>

  created(): void {
    this.getRooms()
  }

  showToday(): void {
    this.current = new Date(new Date().toDateString())
  }

  onSelect(day: Date): void {
    if (this.breakpoints.width >= 1200) {
      this.current = day
      this.period = 'day'
    }
  }

  onEdit(booking: BookingModel): void {
    const from = toLocalDateISO(new Date(booking.start))
    const to = toLocalDateISO(new Date(booking.end))
    this.model = {
      id: booking.id,
      roomId: booking.resourceId,
      dateFrom: from.slice(0, 10),
      timeFrom: from.slice(11, 16),
      dateTo: to.slice(0, 10),
      timeTo: to.slice(11, 16),
    }
    this.showEditBooking = true
  }

  addBooking(): void {
    if (this.period === 'day') {
      this.model = {
        dateFrom: toLocalDateISO(this.current, false),
        timeFrom: '09:00',
        dateTo: toLocalDateISO(this.current, false),
        timeTo: '18:00',
      }
    } else {
      this.model = {}
    }
    this.showEditBooking = true
  }
}
